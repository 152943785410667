import { faker } from '@faker-js/faker';
import { Ambassador, AmbassadorList } from 'data/types';

export const ambassadorMock = (): Ambassador => ({
    id: faker.number.int(),
    name: faker.person.fullName(),
    social_media: {
        instagram: faker.datatype.boolean() ? faker.internet.url() : null,
        facebook: faker.datatype.boolean() ? faker.internet.url() : null,
    },
    cupom: faker.string.alphanumeric(),
    available_balance: faker.number.float(),
    followers: faker.number.int(),
    email: faker.internet.email(),
    phone: faker.phone.number(),
    stores_cupom: faker.number.int()
});

export const ambassadorListMock = ({ limit = 10, page = 1 } = {}): AmbassadorList => {
    const list = Array(100)
        .fill(0)
        .map(() => ambassadorMock());
    return {
        results: list.slice((page - 1) * limit, page * limit),
        next: '',
        previous: '',
        count: 100,
    };
};
