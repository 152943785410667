import { time } from 'console';
import { useToggle } from 'hooks';
import { useState } from 'react';

export type TUseModalSize = 'sm' | 'lg' | 'xl' | undefined;
export type TUseModal = {
    isOpen: boolean;
    size: TUseModalSize;
    className: string;
    scroll: boolean;
    timeout: number | undefined;
    toggleModal: () => void;
    openModal: () => void;
    closeModal: () => void;
    openModalWithSize: (size: TUseModalSize) => void;
    openModalWithClass: (className: string) => void;
    openModalWithScroll: () => void;
    openModalCustom: (options: Partial<TUseModal>) => void;
};

export default function useModal() {
    const [isOpen, toggleModal, openModal, closeModal] = useToggle();

    const [size, setSize] = useState<TUseModalSize>('sm');
    const [className, setClassName] = useState<string>('');
    const [scroll, setScroll] = useState<boolean>(false);
    const [timeout, setTimeout] = useState<number | undefined>();

    // Opens large modal
    const openModalWithSize = (size: TUseModalSize) => {
        setSize(size);
        setClassName('');
        setScroll(false);
        toggleModal();
    };

    // Opens modal with custom class
    const openModalWithClass = (className: string) => {
        setClassName(className);
        setScroll(false);
        toggleModal();
    };

    // Opens large modal
    const openModalWithScroll = () => {
        setScroll(true);
        setSize('sm');
        setClassName('');
        toggleModal();
    };

    // Opens customized modal
    const openModalCustom = ({ size, className, scroll, timeout }: Partial<TUseModal>) => {
        if(scroll) setScroll(true);
        if(size) setSize('sm');
        if(className) setClassName('');
        if(timeout !== undefined) setTimeout(timeout);
        toggleModal();
    };

    const response: TUseModal = {
        isOpen,
        size,
        className,
        scroll,
        timeout,
        toggleModal,
        openModal,
        closeModal,
        openModalWithSize,
        openModalWithClass,
        openModalWithScroll,
        openModalCustom,
    };
    return response;
}
