import { Session } from "data/types";

export const getAuthUser = (): Session | null => {
    const user = sessionStorage.getItem('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
export const saveAuthUser = (data: Session) => {
    sessionStorage.setItem('user', JSON.stringify(data));
};
export const deleteAuthUser = () => {
    sessionStorage.removeItem('user');
};