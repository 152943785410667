import { DbId, ShopProduct } from 'data/types';
import { CartActionType, CartActionTypes } from './constants';

// export type CartActionType = {
//     type:
//         | CartActionTypes.ADD_PRODUCT
//         | CartActionTypes.EDIT_PRODUCT
//         | CartActionTypes.DELETE_PRODUCT
//         | CartActionTypes.RESET;
//     payload: {} | string;
// };

export const selectProductOnCart = (id: DbId): CartActionType => ({
    type: CartActionTypes.SELECT_PRODUCT,
    payload: { id },
});

export const unselectProductOnCart = (id: DbId): CartActionType => ({
    type: CartActionTypes.UNSELECT_PRODUCT,
    payload: { id },
});


export const SelectAllProductsOnCart = (): CartActionType => ({
    type: CartActionTypes.SELECT_ALL_PRODUCT,
    payload: {},
});

export const resetProductSelectionsOnCart = (): CartActionType => ({
    type: CartActionTypes.RESET_SELECT,
    payload: {},
});

export const addProductOnCart = (data: ShopProduct): CartActionType => ({
    type: CartActionTypes.ADD_PRODUCT,
    payload: { data },
});

export const editProductOnCart = (id: DbId, data: ShopProduct): CartActionType => ({
    type: CartActionTypes.EDIT_PRODUCT,
    payload: { id, data },
});

export const deleteProductOnCart = (id: DbId): CartActionType => ({
    type: CartActionTypes.DELETE_PRODUCT,
    payload: { id },
});

export const deleteManyProductOnCart = (ids: DbId[]): CartActionType => ({
    type: CartActionTypes.DELETE_MANY_PRODUCTS,
    payload: { ids },
});

export const resetProductsOnCart = (): CartActionType => ({
    type: CartActionTypes.RESET,
    payload: {},
});
