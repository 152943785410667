import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Cart from './cart/reducers';

export default combineReducers({
    Auth,
    Layout,
    Cart,
});
