import { all, fork, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
    addProductOnCart,
    deleteManyProductOnCart,
    deleteProductOnCart,
    editProductOnCart,
    resetProductSelectionsOnCart,
    resetProductsOnCart,
    selectProductOnCart,
    unselectProductOnCart,
} from './actions';
import { CartAction, CartActionTypes } from './constants';
import { DbId, ShopProduct } from 'data/types';

/**
 * Add a product to cart
 * @param {*} payload - ShopProduct object
 */
function* add({ payload }: CartAction<ShopProduct>): SagaIterator {
    if (!payload.data) return;
    yield call(addProductOnCart, payload.data);
}
function* edit({ payload: { id, data } }: CartAction<ShopProduct>): SagaIterator {
    if (!id || !data) return;
    yield call(editProductOnCart, id, data);
}
function* remove({ payload: { id } }: CartAction<{ id: DbId }>): SagaIterator {
    if (!id) return;
    yield call(deleteProductOnCart, id);
}
function* removeMany({ payload: { ids } }: CartAction<{ ids: DbId[] }>): SagaIterator {
    if (!ids) return;
    yield call(deleteManyProductOnCart, ids);
}
function* reset(): SagaIterator {
    yield call(resetProductsOnCart);
}
function* select({ payload: { id } }: CartAction<{ id: DbId }>): SagaIterator {
    if (!id) return;
    yield call(selectProductOnCart, id);
}
function* unselect({ payload: { id } }: CartAction<{ id: DbId }>): SagaIterator {
    if (!id) return;
    yield call(unselectProductOnCart, id);
}
function* resetSelect({ payload: { id } }: CartAction<{ id: DbId }>): SagaIterator {
    if (!id) return;
    yield call(resetProductSelectionsOnCart);
}

export function* watchAdd() {
    yield takeEvery(CartActionTypes.ADD_PRODUCT, add);
}
export function* watchEdit() {
    yield takeEvery(CartActionTypes.EDIT_PRODUCT, edit);
}
export function* watchRemove() {
    yield takeEvery(CartActionTypes.DELETE_PRODUCT, remove);
}
export function* watchRemoveMany() {
    yield takeEvery(CartActionTypes.DELETE_MANY_PRODUCTS, removeMany);
}
export function* watchReset() {
    yield takeEvery(CartActionTypes.RESET, reset);
}

export function* watchSelect() {
    yield takeEvery(CartActionTypes.SELECT_PRODUCT, select);
}
export function* watchUnselect() {
    yield takeEvery(CartActionTypes.UNSELECT_PRODUCT, unselect);
}
export function* watchResetSelect() {
    yield takeEvery(CartActionTypes.UNSELECT_PRODUCT, resetSelect);
}

function* cartSaga() {
    yield all([
        fork(watchAdd),
        fork(watchEdit),
        fork(watchRemove),
        fork(watchRemoveMany),
        fork(watchReset),
        fork(watchSelect),
        fork(watchUnselect),
        fork(watchResetSelect),
    ]);
}

export default cartSaga;
