import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { productListMock, productMock } from './mock/product';
import Endpoints from './endpoints';
import { orderListMock, orderMock, productCategoryListMock, storeMock } from './mock';
import { Session } from './types';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough', delayResponse: 2000 });

export function configureFakeBackend() {
    let users: Session[] = [
        {
            id: 1,
            email: 'admin',
            password: 'admin',
            first_name: 'Admin',
            last_name: 'User',
            role: 'Admin',
            enabled: true,
            created_date: new Date().toISOString(),
            store: null,
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJ1c2VyX2lkIjoxfQ.Aj9xzZTeLI6_Mdtb_tvAgPcxwPeZ54rDh2v-URvgwgU',
        },
        {
            id: 2,
            email: 'user',
            password: 'user',
            first_name: 'Test',
            last_name: 'User',
            role: 'Store',
            enabled: true,
            created_date: new Date().toISOString(),
            store: storeMock({agorachei_pro: true}),
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJ1c2VyX2lkIjoyfQ.boXXrkXhVw45cE_QRsXo4vlFGwg4pcHm64fs5ShNAYg',
        },
        {
            id: 3,
            email: 'disable',
            password: 'user',
            first_name: 'Test',
            last_name: 'User',
            role: 'Store',
            store: storeMock(),
            has_deactivated_store: true,
            enabled: false,
            created_date: new Date().toISOString(),
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJ1c2VyX2lkIjozfQ.hFLmml23L6DOLKD05viQF6zxXJsm6gr3jcm36WhtbPM',
        },
        {
            id: 3,
            email: 'notpro',
            password: 'user',
            first_name: 'Test',
            last_name: 'User',
            role: 'Store',
            store: storeMock({agorachei_pro: false}),
            has_deactivated_store: true,
            enabled: false,
            created_date: new Date().toISOString(),
            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJ1c2VyX2lkIjozfQ.hFLmml23L6DOLKD05viQF6zxXJsm6gr3jcm36WhtbPM',
        },
    ];

    mock.onPost(Endpoints.login).reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.email === params.email && user.password === params.password;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let user = filteredUsers[0];
                    resolve([200, { refresh: user.token, access: user.token }]);
                } else {
                    // else return error
                    resolve([401, { error: 'Username or password is incorrect' }]);
                }
            }, 1000);
        });
    });

    mock.onPost(Endpoints.register).reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // add new users
                let [first_name, last_name] = params.fullname.split(' ');
                let newUser: Session = {
                    id: users.length + 1,
                    email: first_name,
                    password: params.password,
                    first_name: first_name,
                    last_name: last_name,
                    role: 'Admin',
                    store: storeMock(),
                    enabled: true,
                    created_date: new Date().toISOString(),
                    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJ1c2VyX2lkIjo0fQ.eiXsnCfUheHuoyZuVp5itGgOwW6c3jAOXAPR48c8CRg',
                };
                users.push(newUser);

                resolve([200, newUser]);
            }, 1000);
        });
    });

    mock.onPost(Endpoints.forgotPassword).reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.email === params.email;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let responseJson = {
                        error: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve([200, responseJson]);
                } else {
                    // else return error
                    resolve([
                        401,
                        {
                            error: 'Sorry, we could not find any registered user with entered email',
                        },
                    ]);
                }
            }, 1000);
        });
    });
    mock.onGet(new RegExp(Endpoints.user + '\\d+')).reply((config) => {
        return [200, users.find((item) => item.id === config.url?.split(Endpoints.user)[1] || item.id === Number(config.url?.split(Endpoints.user)[1]))];
    });

    // Product endpoints

    mock.onGet(Endpoints.product).reply((config) => {
        return [200, productListMock(config.params)];
    });

    mock.onGet(Endpoints.productCategory).reply((config) => {
        return [200, productCategoryListMock(config.params)];
    });

    mock.onGet(new RegExp(Endpoints.product + '\\d+')).reply(200, productMock());

    mock.onPost(Endpoints.product).reply(200, productMock());

    mock.onPut(new RegExp(Endpoints.product + '\\d+')).reply((config) => {
        let params = JSON.parse(config.data);
        return [200, productMock(params)];
    });

    mock.onPatch(new RegExp(Endpoints.product + '\\d+')).reply((config) => {
        let params = JSON.parse(config.data);
        return [200, productMock(params)];
    });

    mock.onDelete(new RegExp(Endpoints.product + '\\d+')).reply(204);

    // Shop / sharedStock endpoints

    mock.onGet(Endpoints.shop).reply((config) => {
        return [200, productListMock(config.params)];
    });
    mock.onGet(new RegExp(Endpoints.shop + '\\?(.*)')).reply((config) => {
        return [200, productListMock(config.params)];
    });

    mock.onGet(new RegExp(Endpoints.shop + '\\d+')).reply(200, productMock());

    // Order endpoints

    mock.onGet(Endpoints.order).reply((config) => {
        return [200, orderListMock(config.params)];
    });
    mock.onGet(new RegExp(Endpoints.order + '\\?(.*)')).reply((config) => {
        return [200, orderListMock(config.params)];
    });

    mock.onGet(new RegExp(Endpoints.order + '\\d+')).reply((config) => {
        return [200, orderMock()];
    });

    mock.onPost(Endpoints.order).reply(200, orderMock());

    mock.onPut(new RegExp(Endpoints.order + '\\d+')).reply((config) => {
        let params = JSON.parse(config.data);
        return [200, orderMock(params)];
    });

    mock.onPatch(new RegExp(Endpoints.order + '\\d+')).reply((config) => {
        let params = JSON.parse(config.data);
        return [200, orderMock(params)];
    });

    mock.onDelete(new RegExp(Endpoints.order + '\\d+')).reply(204);
}
