import { faker } from '@faker-js/faker';
import { Address, AddressList } from 'data/types';

export const addressMock = (): Address => ({
    id: faker.number.int(),
    user: faker.number.int(),
    store: faker.number.int(),
    city: faker.location.city(),
    state: faker.location.state(),
    street_address: faker.location.streetAddress(),
    number: faker.number.int().toString(),
    neighborhood: faker.word.words(1),
    latitude: faker.location.latitude(),
    longitude: faker.location.latitude(),
    cep:
        faker.number.int({ min: 10000, max: 99999 }).toString() +
        '-' +
        faker.number.int({ min: 100, max: 999 }).toString(),
});

export const AddressListMock = ({ limit = 10, page = 1 } = {}): AddressList => {
    const list = Array(100)
        .fill(0)
        .map(() => addressMock());
    return {
        results: list.slice((page - 1) * limit, page * limit),
        next: '',
        previous: '',
        count: 100,
    };
};
