import { ApiResponse, DbId, Session, User, UserList } from 'data/types';
import { APICore } from './apiCore';

export const UserRepository = {
    api: new APICore(),

    getAuth: function (): Session | null {
        return this.api.getLoggedInUser();
    },

    list: async function (
        params: { page?: number; limit?: number; email?: string } = {}
    ): Promise<ApiResponse<UserList>> {
        return this.api.get(`${this.api.endpoints.user}`, params);
    },

    get: async function (id: DbId): Promise<ApiResponse<User>> {
        return this.api.get(`${this.api.endpoints.user}${id}`);
    },

    create: async function (data: Partial<User>): Promise<ApiResponse<User>> {
        return this.api.create(`${this.api.endpoints.user}`, data);
    },

    update: async function (id: DbId, data: Partial<User>): Promise<ApiResponse<User>> {
        return this.api.updatePatch(`${this.api.endpoints.user}${id}`, data);
    },

    upload: async function (id: DbId, data: Partial<IUserApiForm>): Promise<ApiResponse<User>> {
        return this.api.updateWithFile(`${this.api.endpoints.user}${id}`, data);
    },

    changePassword: async function (data: Partial<IUserPasswordApiForm>): Promise<ApiResponse<IUserPasswordApiResponse>> {
        return this.api.create(`${this.api.endpoints.changePassword}`, data);
    },

    delete: async function (id: DbId): Promise<ApiResponse<any>> {
        return this.api.delete(`${this.api.endpoints.user}${id}`);
    },
};

export type IUserApiForm = Omit<User, 'photo'> & {
    photo: File;
    store_cnpj: string | null;
    store_name: string | null;
};

export type IUserPasswordApiForm = {
    id: DbId;
    new_password1: string | null;
    new_password2: string | null;
    old_password: string | null;
};

export type IUserPasswordApiResponse = {
    message?: string;
    new_password1?: string;
    new_password2?: string;
    old_password?: string;
};