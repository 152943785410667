export const UserRoleEnum = {
    Admin: 'Admin',
    Store: 'Store',
};
export const UserRoleList = Object.values(UserRoleEnum);

export const BankAccountTypesEnum = {
    Current: 'corrente',
    Saving: 'poupanca',
};
export const BankAccountTypesList = Object.values(BankAccountTypesEnum);

export const ProductSizeEnum = {
    PP: 'PP',
    P: 'P',
    M: 'M',
    G: 'G',
    GG: 'GG',
    U: 'U',
};
export const ProductSizeList = Object.values(ProductSizeEnum);

export const OrderStatusEnum = {
    DealProcess: 'em_conversa',
    Unpaid: 'aguardando_pagamento',
    Paid: 'aguardando_envio',
    Send: 'a_caminho',
    Delivered: 'entregue',
    Finished: 'finalizado',
    Canceled: 'cancelado',
};
export const OrderStatusList = Object.values(OrderStatusEnum);
export const OrderStatusSuccessList = Object.values(OrderStatusEnum).filter(
    (item) => ![OrderStatusEnum.Canceled].includes(item)
);

export const OrderPaymentMethodEnum = {
    Manual: 'manual',
    Pix: 'pix',
    CreditCard: 'credit_card',
};
export const OrderPaymentMethodList = Object.values(OrderPaymentMethodEnum);
export const OrderPaymentMethodAvailableList = Object.values(OrderPaymentMethodEnum).filter(
    (i) => ![OrderPaymentMethodEnum.Manual].includes(i)
);

export const OrderPaymentStatusEnum = {
    Paid: 'Paid',
    PaymentFailed: 'PaymentFailed',
    Unpaid: 'Unpaid',
    AwaitingAuthorization: 'AwaitingAuthorization',
};
export const OrderPaymentStatusList = Object.values(OrderPaymentStatusEnum);

export const OrderDiscountTypeEnum = {
    Coin: 'coin',
    Voucher: 'voucher',
    Coupon: 'coupon',
};
export const OrderDiscountTypeList = Object.values(OrderDiscountTypeEnum);

export const OrderShippingTypeEnum = {
    Pac: 'pac',
    Sedex: 'sedex',
};
export const OrderShippingTypeList = Object.values(OrderShippingTypeEnum);
