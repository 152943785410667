import { DbId, ShopCart, ShopProduct } from "data/types";

export enum CartActionTypes {
    SELECT_PRODUCT = '@@cart/SELECT_PRODUCT',
    UNSELECT_PRODUCT = '@@cart/UNSELECT_PRODUCT',
    SELECT_ALL_PRODUCT = '@@cart/SELECT_ALL_PRODUCT',
    RESET_SELECT = '@@cart/RESET_SELECT',

    ADD_PRODUCT = '@@cart/ADD_PRODUCT',
    EDIT_PRODUCT = '@@cart/EDIT_PRODUCT',
    DELETE_PRODUCT = '@@cart/DELETE_PRODUCT',
    DELETE_MANY_PRODUCTS = '@@cart/DELETE_MANY_PRODUCTS',

    RESET = '@@cart/RESET',
}

export type CartActionType = {
    type: CartActionTypes;
    payload: {
        id?: DbId;
        ids?: DbId[];
        data?: ShopProduct;
        error?: string;
    };
};

export type State<T> = {
    id?: DbId;
    ids?: DbId[];
    data?: T;
    loading?: boolean;
};

export type CartAction<T> = {
    type: CartActionTypes;
    payload: State<T>;
};
